import React from "react"
import PropTypes from "prop-types"
import Button from "./core/Button"
import Image from "./core/Image"

const Tout1 = ({
  title,
  text,
  ctaLink,
  ctaText,
  image1,
  image2,
  className
}) => {
  return (
    <section
      className={`container tout1 ${className}`}
    >
      <div 
        className="tout1__images"
        data-sal="fade"
        data-sal-duration="1000"
        data-sal-easing="ease-out"
      >
        <Image className="tout1__image1" data={image1} alt=""/>
        <Image className="tout1__image2" data={image2} alt=""/>
      </div>
      <div 
        className="tout1__copy"
        data-sal="fade"
        data-sal-duration="1500"
        data-sal-easing="ease"        
      >
        <div className="tout1__copy__inner">
          <h2 className="tout1__title">{title}</h2>  
          <p className="tout1__text">{text}</p>  
          <Button 
            colour="primary" 
            text={ctaText} 
            type="link" 
            link={ctaLink}
            gatsbyLink={true}  
          />
        </div>
      </div>
    </section>
  )
}

Tout1.defaultProps = {
  className: "tout",
  title: "",
  text: "",
}

Tout1.propTypes = {
  className: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.node,
  image1: PropTypes.object,
  image2: PropTypes.object,
}

export default Tout1
