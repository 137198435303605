import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import SEO from "../components/SEO"
import Hero from "../components/Hero"
import Tout1 from "../components/Tout1"
import FormBlock from "../components/FormBlock"
import Tout2 from "../components/Tout2"

const Homepage = () => {

  const data = useStaticQuery(graphql`
    {
      page: mdx(fileAbsolutePath: { regex: "/content/index/" }) {
        body
        frontmatter {
          path
          title
          hero {
            image
          }
          tout1 {
            title
            text
            ctaLink
            ctaText
            className
            image1
            image2
          }
          formBlock {
            title
            className
          }
          tout2 {
            title
            text
            className
            image
          }
        }
      }
      images: allFile(filter: {extension: {regex: "/(jpg)|(jpeg)|(png)/"}, relativeDirectory: {eq: "assets/images"}}) {
        edges {
          node {
            childImageSharp {
              id
              fluid {
                originalName
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
      }
    }
  `)
  
  const {
    hero,
    tout1,
    tout2,
    formBlock
  } = data.page.frontmatter

  const images = data.images.edges.map(data => data.node.childImageSharp.fluid)
  const getImage = name => images.find(({originalName}) => originalName === name)

  return (
    <>
      <SEO title="Home" />
      
      <Hero image={getImage(hero.image)} />
      
      <Tout1 
        {...tout1} 
        image1={getImage(tout1.image1)} 
        image2={getImage(tout1.image2)}
      />
      
      <FormBlock {...formBlock} />
      
      <Tout2 
        {...tout2}
        image={getImage(tout2.image)}
      />
    </>
  )
}

export default Homepage