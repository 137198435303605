import React from "react"
import PropTypes from "prop-types"
import Image from "./core/Image"

const Tout2 = ({
  title,
  text,
  image,
  className
}) => {
  return (
    <section 
      className={`container tout2 ${className}`}
    >
        <div 
          className="tout2__image"
          data-sal="fade"
          data-sal-duration="1000"
          data-sal-easing="ease"          
        >
          <Image className="tout2__img" data={image} alt=""/>
        </div>

        <div 
          className="tout2__copy"
          data-sal="fade"
          data-sal-delay="500"
          data-sal-duration="1000"
          data-sal-easing="ease"            
        >
          <h2 className="tout2__title">{title}</h2>
          <p className="tout2__text">{text}</p>
        </div>
    </section>
  )
}

Tout2.defaultProps = {
  className: "tout2",
}

Tout2.propTypes = {
    title: PropTypes.string.isRequired,
    text: PropTypes.node,
    image: PropTypes.object,
    className: PropTypes.string.isRequired
}

export default Tout2
